<template>
  <div class="page">
    <div class="container">
      <div class="header">
        {{ configDetail.title }}
      </div>
      <div class="technologyBody">
        <img :src="configDetail.iconImg" alt="" class="iconImg" />
        <div class="text">{{ configDetail.iconTitle }}</div>
      </div>
      <div class="technologyFooter">
        <div class="describe">
          {{ configDetail.describe }}
        </div>
        <div class="mail">联系邮箱：{{ configDetail.mail }}</div>
        <div class="phone">联系电话：{{ configDetail.phone }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getConfig } from "@/api/security";
export default {
  name: "TechnicalConfig",
  data() {
    return {
      configDetail: {},
      key: "",
    };
  },
  mounted() {
    this.key = this.$route.query.key;
    let config = {
      key: "technical_support",
    };
    this.requestGetConfig(config);
  },
  methods: {
    async requestGetConfig(config) {
      try {
        const result = await getConfig(config);
        if (result.code === 20000 || result.status_code === 1000) {
          let content = JSON.parse(result.data.content);
          let configDetail = content.find((item) => {
            return item.key === this.key;
          });
          if (configDetail) {
            this.configDetail = configDetail;
          } else {
            this.configDetail = {};
          }
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  overflow: hidden;
  .container {
    width: 320px;
    margin: 0 auto;
    .header {
      font-size: 24px;
      padding: 50px 0px 25px 0px;
      font-weight: 600;
    }
    .technologyBody {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      margin-top: 80px;
      .iconImg {
        width: 80px;
        height: 80px;
      }
      .text {
        font-size: 14px;
        color: #aaaaaa;
        text-align: center;
        margin-top: 15px;
      }
    }
    .technologyFooter {
      margin-top: 50px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      .mail {
        margin-top: 10px;
      }
      .phone {
        margin-top: 10px;
      }
    }
  }
}
</style>
